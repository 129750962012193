import {
  Output,
  TemplateRef,
  ViewChild,
  Component,
  ContentChild,
  ElementRef,
  ViewEncapsulation,
  Input,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnChanges,
  OnInit,
} from '@angular/core';
import { trigger } from '@angular/animations';
import { expandAnimation } from './collapse-section.constants';
import { of, Subject } from 'rxjs';
import { MppAutoUnsubscribeComponent } from '../../helpers';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'mpp-collapse-section',
  templateUrl: './collapse-section.component.html',
  styleUrls: ['./collapse-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-collapse-section' },
  animations: [trigger('expandAnimation', expandAnimation)],
})
export class MppCollapseSectionComponent
  extends MppAutoUnsubscribeComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  public readonly summary: string;

  @Input()
  public readonly isDefaultOpen = true;

  @Input()
  public readonly data: any | null;

  @Output()
  public readonly onBeforeOpen: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onClose: EventEmitter<void> = new EventEmitter();

  @ContentChild('summaryTemplate')
  public readonly summaryTemplate: TemplateRef<any>;

  @ContentChild('contentTemplate')
  public readonly contentTemplate: TemplateRef<any>;

  @ViewChild('headerTemplate', { static: true })
  private readonly headerTemplate: ElementRef<HTMLElement>;

  public isLoading = false;
  public isExpanded = false;

  private readonly canActivate$$: Subject<void> = new Subject();
  private readonly beforeOpen$$: Subject<void> = new Subject();
  private readonly close$$: Subject<void> = new Subject();

  private readonly triggersMap: Map<boolean, Subject<void>> = new Map([
    [false, this.beforeOpen$$],
    [true, this.close$$],
  ]);

  @HostListener('click', ['$event'])
  public onSectionClick(event: PointerEvent): void {
    const target: HTMLElement = event.target as HTMLElement;

    const isHeader = this.headerTemplate.nativeElement.contains(target);
    const isButton = !!target.closest('button');

    if (this.isLoading) {
      return;
    }

    if (!(isHeader && (!this.isExpanded || isButton))) {
      return;
    }

    this.triggersMap.get(this.isExpanded)?.next();
  }

  public ngOnChanges(): void {
    if (this.data) {
      this.canActivate$$.next();
    }
  }

  public ngOnInit(): void {
    this.beforeOpen$$
      .pipe(
        tap(() => {
          this.onBeforeOpen.emit();
          this.isLoading = true;
        }),
        switchMap(() => (this.data ? of(this.data) : this.canActivate$$.asObservable())),
        takeUntil(this.destroy$$)
      )
      .subscribe(() => {
        this.isExpanded = true;
        this.isLoading = false;
      });

    this.close$$.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      this.onClose.emit();

      this.isExpanded = false;
    });

    if (this.isDefaultOpen) {
      this.beforeOpen$$.next();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.canActivate$$.complete();
    this.beforeOpen$$.complete();
    this.close$$.complete();
  }
}
